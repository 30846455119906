<template>
  <apexchart
    ref="scatterChart"
    :height="chart__height"
    :type="chart__type"
    :options="chart__options"
    :series="chart__series"
    v-if="show"
  >
  </apexchart>
</template>
<script>
export default {
  props: [
    "chartType",
    "chartOptions",
    "chartSeries",
    "chartHeight",
    "chartRefresh",
    "show",
  ],
  name: "ScatterChart",
  data: () => ({
    chart__series: [],
    chart__type: "",
    chart__height: 200,
    chart__options: {},
    showChart: false
  }),
  mounted() {
    setTimeout(() => {
      console.log("chartSeries-->2", this.chartSeries);
      this.refreshData();
    }, 1000);
  },
  created() {},
  methods: {
    refreshData() {
      this.chart__series=this.chartSeries
      this.chart__type = this.chartType;
      this.chart__height = this.chartHeight;
      this.chart__options = this.chartOptions;
      this.showChart = this.show;
    },
  },
  watch: {
    show: function () {
      console.log("refreshData");
      this.refreshData();
    },
  },
};
</script>