<template>
  <div id="crm-filtering">
    <v-row>
      <v-col cols="12" sm="12" class="pa-1">
        <div class="display-1">
          CRM Segmentation
          <v-btn
            @click="next()"
            class="float-right btns color-white"
            color="#109CF1"
            elevation="0"
            >Next</v-btn
          >
          <v-btn
            @click="$router.go(-1)"
            class="float-right btns color-white mr-4"
            color="#109CF1"
            elevation="0"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row v-if="loading.cus_view && loading.scatter && loading.cus">
      <v-col cols="12" sm="12" class="h-screen">
        <v-progress-circular
          id="sharethis-loading"
          :size="50"
          color="#94D227"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <div v-else>
      <v-row class="my-14">
        <v-col cols="12" sm="4" class="pa-1 flex items-center">
          <v-menu
            v-model="menu"
            :close-on-content-click="true"
            :nudge-width="100"
            transition="slide-y-transition"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="0"
                color="#BBE5FF"
                class="black--text text-capitalize shadow-none"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-cog-outline</v-icon>
                Clustering Technique
              </v-btn>
            </template>

            <v-card>
              <v-list class="pa-0">
                <v-radio-group
                  v-model="approchName"
                  :mandatory="false"
                  class="ma-0 pa-0"
                  hide-details=""
                >
                  <v-list-item
                    class="h-12"
                    v-for="(item, index) in approachs"
                    :key="index"
                  >
                    <v-list-item-action>
                      <v-radio
                        @click="setApproch(item.key)"
                        :value="item.key"
                      ></v-radio>
                    </v-list-item-action>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-radio-group>
              </v-list>
            </v-card>
          </v-menu>
            <v-text-field
            label="Sample Size"
            outlined
            type="number"
            v-model="sampleSize"
            dense
            @keyup.enter.native="setApprochData()"
            @blur="setApprochData()"
            class="ml-5 sample-size"
            hide-details=""
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-14">
        <v-col cols="4" sm="4" md="5" class="pa-1">
          <v-card class="tgdf-avg mx-auto pa-0">
            <div class="tgdf-top pa-8 pb-0">
              <div
                class="
                  display-5
                  font-weight-bold
                  flex
                  justify-between
                  items-center
                " style="color:#109CF1"
              >
                Customer View
              </div>
            </div>
            <div class="tgdf-table-avg px-5 py-3 my-12">
              <v-row>
                <v-col cols="12" sm="12" class="pa-0">
                  <table class="w-full mt-6">
                    <thead class="h-16">
                      <tr>
                        <th></th>
                        <th class="avg-title">{{ customer_view.col_1 }}</th>
                        <th class="avg-title">{{ customer_view.col_2 }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in customer_view.avg"
                        :key="index"
                      >
                        <td class="flex align-center h-10">
                          <span
                            :class="
                              'avg-item avg-status avg-status-' +
                              item.tier.toLowerCase()
                            "
                          ></span>
                          <span class="ml-2 font-weight-bold tier-title">
                            {{ item.tier }}
                          </span>
                        </td>
                        <td
                          class="text-center h-10 tier-num"
                          :style="'background:' + item.num_1.color"
                        >
                          {{ item.num_1.num }}
                        </td>
                        <td
                          class="text-center h-10 tier-num"
                          :style="'background:' + item.num_2.color"
                        >
                          {{ item.num_2.num }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col cols="8" sm="8" md="7" class="pa-1 pl-8">
          <v-card class="mx-auto chart pa-5">
            <div
              class="
                display-5
                font-weight-bold
                my-4
                flex
                justify-between
                items-center
              " style="color:#109CF1"
            >
              Customer Segmentation
            </div>
            <ScatterChart
              :show="scatterChart.show"
              :chartSeries="scatterChart.series"
              :chartOptions="scatterChart.options"
              chartHeight="250"
              chartType="scatter"
              :chartRefresh="chartRefresh"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row class="my-5" id="Customer-high-tier-summary">
        <v-col cols="12" sm="12" class="py-8 pa-0">
          <div class="display-6 font-weight-bold">
            Customer Tier Summary
          </div>
        </v-col>
        <v-col cols="12" sm="12" class="pa-0">
          <v-data-table
            show-expand
            single-expand
            show-select
            :item-key="item__key"
            v-model="selected"
            :single-select="singleSelect"
            :headers="table__header"
            :items="table__data"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
            @item-expanded="loadCats"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="expand-item pa-5">
                <div
                  class="
                    expand-item-title
                    text-center
                    center
                    mb-5
                    font-weight-bold
                  "
                >
                  All Categories
                </div>
                <div class="grid grid-cols-6 expand-item-cats">
                  <span v-for="(cat, i) in item.cats" :key="i">
                    {{ cat }}
                  </span>
                </div>
              </td>
            </template>
            <template v-slot:[`item.tier`]="{ item }">
              <v-chip color="#f88787" dark v-if="item.tier == 'low'">
                {{ item.tier }}
              </v-chip>
              <v-chip color="#ffe380" dark v-else-if="item.tier == 'medium'">
                {{ item.tier }}
              </v-chip>
              <v-chip color="#87f8b4" dark v-else>
                {{ item.tier }}
              </v-chip>
            </template>
          </v-data-table>
          <v-pagination
            id="Customer-high-tier-summary-pagination"
            :total-visible="7"
            v-model="page"
            :length="pageCount"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ScatterChart from "./Charts/SegmentationChart.vue";
export default {
  components: { ScatterChart },
  data() {
    return {
      sampleSize:60,
      item__key: "customer_id",
      loading: {
        cus_view: false,
        cus: false,
        scatter: false,
      },
      approachs: [],
      singleSelect: false,
      selected: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      table__header: [],
      table__data: [],
      scatterChart:{
          series:[],
          options:{},
          show:false
      },
      chartRefresh: false,
      customer_view: {},
      menu: false,
      approchName: this.$store.getters.getApproch,
      params: this.$store.getters.getParams,
      all_categories_summary_data: [],
    };
  },
  mounted() {
    this.setApprochData();
  },
  methods: {
    setApproch(item) {
      this.approchName = item;
      this.$store.commit("setApproch", item);
      this.scatterChart.show=false
      this.setApprochData();
    },
    setApprochData() {
      this.scatterChart.show=false
      this.getApproach();
      this.get_scatter_data();
      this.get_all_categories_summary_data();
      this.get_customer_view()
    },
    loadCats({ item }) {
      return (item.cats = this.all_categories_summary_data.find(
        (x) => x["CustomerID"] == item["CustomerID"]
      )[this.params['category_column']+'s']);
    },

    format__data(data,params) {
      this.table__header = [];
      this.table__data = [];
      Object.keys(data[0]).forEach((el) => {
        if (el != "index") this.table__header.push({ text: el, value: el });
      });
      this.item__key = Object.keys(data[0])[0];
      console.log("this.table__header==>", this.table__header);
      data.forEach((el) => {
        el[params['category_column']+'s'] = el[params['category_column']];
        el[params['category_column']] = el[params['category_column']][0];
        el["ShareThisCategories"] = el["ShareThisCategories"][0];
        this.table__data.push(el);
      });
      console.log("this.table__data==>", this.table__data);
    },
    // Apis functions
    async getApproach() {
      await this.$http
        .post("/clustering_techniques")
        .then((res) => {
          console.log("--clustering_approaches--->", res.data);
          this.approachs = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async get_all_categories_summary_data() {
      await this.$http
        .post("/crm_clusters_view?clustering_technique=" + this.approchName)
        .then((res) => {
          console.log(
            "--crm_clusters_view?clustering_technique=" +
              this.approchName +
              "--->",
            res.data
          );
          this.loading.cus=false
          this.all_categories_summary_data = res.data.data;
          this.format__data(res.data.data,res.data.params);
          this.$store.commit('setParams',res.data.params)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async get_scatter_data() {
      await this.$http
        .post("/crm_clusters_scatter?clustering_technique=" + this.approchName+"&sampling_number="+this.sampleSize)
        .then((res) => {
          console.log("--" + this.approchName + "/scatter--->", res.data);
          this.scatterChart.series=res.data.series
          this.scatterChart.options=res.data.options
          this.scatterChart.show=true
           this.loading.scatter=false
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async get_customer_view() {
      await this.$http
        .post("/crm_clusters_summary?clustering_technique=" + this.approchName)
        .then((res) => {
          console.log("--crm_clusters_summary", res.data);
          this.customer_view=res.data
           this.loading.cus_view=false
        })
        .catch((err) => {
          console.log(err);
        });
    },

    randomNumber(min, max) {
      let randomdata = [];
      for (let index = 0; index < max; index++) {
        randomdata.push(Math.floor(Math.random() * (500 - min + 1)) + min);
      }
      return randomdata;
    },
    approach() {
      this.desserts.filter((dessert) => {
        dessert.calories += 1;
        dessert.protein += 1;
        dessert.carbs += 1;
        dessert.fat += 1;
        return dessert;
      });
      this.data.datasets[0].data = this.randomNumber(1, 31);
      this.avg.item1.filter((e) => (e.num += 5));
      this.avg.item2.filter((e) => (e.num += 3));
      this.chartRefresh = !this.chartRefresh;
    },
    getStepper() {
      console.log(this.$store.getters.getStepper);
    },
    next() {
      this.$store.commit("setStepper", { type: "crm", val: 2 });
      this.$router.push({ path: "/tier-processing" });
    },
  },
};
</script>
<style lang="scss" scoped>
#crm-filtering::v-deep {
  .sample-size{width: 16% !important;    max-width: 16% !important;}
  .v-data-table-header {
    background: #009fff42 !important;
  }
  .border-t {
    border-top: 2px solid #e5e5e5 !important;
  }
  .border-l {
    border-left: 1px solid #e5e5e5 !important;
  }
  .avg-title {
    font-size: 15px !important;
  }
  .avg-status-low {
    background: #f88787;
  }
  .avg-status-medium {
    background: #ffe380;
  }
  .avg-status-high {
    background: #87f8b4;
  }
  .avg-status {
    width: 15px;
    height: 15px;
    border-radius: 1px;
  }
  .badges .badge-green {
    background: #87f8b4;
  }
  .badges .badge-yellow {
    background: #ffe380;
  }
  .badges .badge-red {
    background: #f88787;
  }
  .avg-number {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 14px;
  }
  .tier-num,
  .tier-title {
    font-size: 14px;
  }
  .badges .badge-green,
  .badges .badge-yellow,
  .badges .badge-red {
    width: 17px;
    height: 17px;
    border-radius: 2px;
  }
  #Customer-high-tier-summary-pagination
    .theme--light.v-pagination
    .v-pagination__item--active {
    background-color: #ffffff !important;
    border-color: #109cf1 !important;
    color: #109cf1 !important;
  }
  #Customer-high-tier-summary-pagination button {
    box-shadow: none !important;
    border: 1px solid rgb(172 174 178) !important;
  }
  #Customer-high-tier-summary-pagination
    button[aria-label="Next page"]:not(button[disabled="disabled"])
    *,
  #Customer-high-tier-summary-pagination
    button[aria-label="Previous page"]:not(button[disabled="disabled"])
    * {
    color: white;
  }
  #Customer-high-tier-summary-pagination
    button[aria-label="Next page"]:not(button[disabled="disabled"]),
  #Customer-high-tier-summary-pagination
    button[aria-label="Previous page"]:not(button[disabled="disabled"]) {
    background: #109cf1;
  }
  #Customer-high-tier-summary-pagination {
    position: absolute !important;
    top: 20px;
    right: 0;
  }
  #Customer-high-tier-summary {
    position: relative !important;
  }
}
</style>